function senp1(pageLevelDefinition = null) {

    // Set pixel
    this.plUrl = "senp.png";

    // Set session duration (miliseconds)
    this.sessionDuration = 30 * 60 * 1000;

    // Set debounce reset timer
    this.debounceResetTimer = null;

    // Set connection speed
    this.connectionSpeed = this.getConnectionSpeed();

    // Set uuid for pixel
    this.uuid = this.generateUUID();

    // Set property for sending pings
    this.sendPings = false;
    var self = this;
    this.executeWithProbability(0.01, function() {
        
        self.sendPings = true;
    });

    // Set page level definition (custom dimensions)
    this.pageLevelDefinition = pageLevelDefinition;
    
    // Send view hit
    var timestamp = this.extractKeyValue("initTimestamp") || Date.now();
    this.sendPl(timestamp,"view");
}

senp1.prototype.sendPl = function(timestamp, type, typeValue = '') { 

    var referrer = document.referrer;
    var xhttp = new XMLHttpRequest();

    var hostname = window.location.hostname;
    if(!hostname.startsWith("www.") && !hostname.startsWith("community.") && !hostname.startsWith("insights.")){
        
        hostname = "www." + hostname;
    }

    var url = "https://sentinelbi.com/" + hostname + "/" + this.plUrl + "?time=" + timestamp + "";

    // add permalink to url
    url = url + "&permalink=" + "/" + window.location.pathname.replace(/(^\/+|\/+$)/g, '') + '/';

    // add page level definition params
    if(this.pageLevelDefinition){
        
        if(this.pageLevelDefinition.intent){

            url = url + "&intent=" + encodeURIComponent(this.pageLevelDefinition.intent);
        }

        if(this.pageLevelDefinition.contentType){

            url = url + "&contentType=" + encodeURIComponent(this.pageLevelDefinition.contentType);
        }

        if(this.pageLevelDefinition.primaryTag){

            url = url + "&primaryTag=" + encodeURIComponent(this.pageLevelDefinition.primaryTag);
        }

        if(this.pageLevelDefinition.primaryCategory){

            url = url + "&primaryCategory=" + encodeURIComponent(this.pageLevelDefinition.primaryCategory);
        }

        if(this.pageLevelDefinition.networkCategory){

            url = url + "&networkCategory=" + encodeURIComponent(this.pageLevelDefinition.networkCategory);
        }

        if(this.pageLevelDefinition.adsTemplate){

            url = url + "&template=" + encodeURIComponent(this.pageLevelDefinition.adsTemplate);
        }

        if(this.pageLevelDefinition.articleType){

            url = url + "&articleType=" + encodeURIComponent(this.pageLevelDefinition.articleType);
        }

        if(this.pageLevelDefinition.userRef !== undefined){

            url = url + "&userRef=" + encodeURIComponent(this.pageLevelDefinition.userRef);
        }

        if(this.pageLevelDefinition.loggedIn !== undefined){

            url = url + "&loggedIn=" + encodeURIComponent(this.pageLevelDefinition.loggedIn);

            var isActiveSession = (this.pageLevelDefinition.loggedIn) ? "1" : "0";
            url = url + "&isActiveSession=" + isActiveSession;
        }

        if(this.pageLevelDefinition.plan !== undefined){

            url = url + "&plan=" + encodeURIComponent(this.pageLevelDefinition.plan);
        }

        if(this.pageLevelDefinition.referrerOverride){

            url = url + "&referrerOverride=" + encodeURIComponent(this.pageLevelDefinition.referrerOverride);
        }
    }

    // add referrer to url, generated in real time
    if(referrer){
        url = url + "&referrer=" + referrer;
    }
    
    // add connection speed
    if (this.connectionSpeed) {
        // add connection speed
        url = url + "&connSpeed=" + this.connectionSpeed;
    }

    var sentinelObject = this.extractKeyValue("config");
    if(sentinelObject){

        var dimensions = sentinelObject.dimensions;
        if(dimensions){
            
            var serializedDimensions = encodeURIComponent(JSON.stringify(dimensions));
            url = url + "&dimensions=" + serializedDimensions;
        }
    
        var userDimensions = sentinelObject.userDimensions;
        if(userDimensions){
            
            var serializedUserDimensions = encodeURIComponent(JSON.stringify(userDimensions));
            url = url + "&userDimensions=" + serializedUserDimensions;
        }
    
        var internalDimensions = sentinelObject.internalDimensions;
        if(internalDimensions){
            
            var serializedInternalDimensions = encodeURIComponent(JSON.stringify(internalDimensions));
            url = url + "&internalDimensions=" + serializedInternalDimensions;
        }
    }

    // check for type and use if not pre-defined rely on referrer
    if(type == "view"){

        type = "pageVisit";

        // check referrer domain to update and set type
        if (referrer && referrer.trim() !== ""){

            var domain = window.location.hostname;
            var referrerDomain = null;

            if(referrer){
                referrerDomain = (new URL(referrer)).hostname;
            }

            if (referrerDomain === domain) {

                type = "pageView";
            } else {

                type = "pageVisit";
            }
        }
    }

    url = url + "&type=" + type;

    url = url + "&typeValue=" + typeValue;

    url = url + "&uuid=" + this.uuid;

    if(this.sendPings){

        url = url + "&isPing=1";
    }

    xhttp.open("GET", url, true);
    xhttp.send();
}

senp1.prototype.sendEvent = function(timestamp, type, typeValue = '', category = '') { 

    var referrer = document.referrer;
    var xhttp = new XMLHttpRequest();

    var hostname = window.location.hostname;
    if(!hostname.startsWith("www.") && !hostname.startsWith("community.") && !hostname.startsWith("insights.")){
        
        hostname = "www." + hostname;
    }

    var url = "https://sentinelbi.com/" + hostname + "/" + this.plUrl + "?time=" + timestamp + "";

    // add permalink to url
    url = url + "&permalink=" + "/" + window.location.pathname.replace(/(^\/+|\/+$)/g, '') + '/';

    var sentinelObject = this.extractKeyValue("config");
    
    var dimensions = sentinelObject.dimensions;
    if(dimensions){
        
        var serializedDimensions = encodeURIComponent(JSON.stringify(dimensions));
        url = url + "&dimensions=" + serializedDimensions;
    }

    var userDimensions = sentinelObject.userDimensions;
    if(userDimensions){
        
        var serializedUserDimensions = encodeURIComponent(JSON.stringify(userDimensions));
        url = url + "&userDimensions=" + serializedUserDimensions;
    }

    url = url + "&type=" + type;

    url = url + "&typeValue=" + typeValue;
    
    url = url + "&category=" + category;

    url = url + "&uuid=" + this.uuid;

    xhttp.open("GET", url, true);
    xhttp.send();
}

senp1.prototype.getConnectionSpeed = function() {
    
    var connectionSpeed = null;
    if(navigator.connection){
        connectionSpeed = navigator.connection.downlink;
    }
    
    return connectionSpeed;
}

senp1.prototype.sendFtPl = function() {

    if(this.sendPings){

        var timestamp = this.extractKeyValue("footerTimestamp") || Date.now();
        this.sendPl(timestamp, "footerPing", timestamp);
    }
}

senp1.prototype.checkForGA = function() {

        // Check for GA4 gtag function
        if (typeof window.gtag === 'function') {

            // Check for gtag script fired
            if (window.dataLayer) {

                // Check for gtm.dom event
                let gtmDOM = window.dataLayer.some((entry) => {

                    if ('event' in entry) {

                        return entry['event'] === 'gtm.dom';
                    }
                });

                if (!gtmDOM) {

                    //GA4 is loaded but has not fired
                    var timestamp = Date.now();
                    this.sendPl(timestamp,"isGATrackerBlocked", 1);
                }
            } else {

                //GA4 is not available
                var timestamp = Date.now();
                this.sendPl(timestamp,"isGATrackerBlocked", 1);
            }

        } else {

            //GA4 is not available
            var timestamp = Date.now();
            this.sendPl(timestamp,"isGATrackerBlocked", 1);
        }
}

senp1.prototype.executeWithProbability = function(probability, callback) {

    if (Math.random() < probability) {
        
        callback();
    }
}

senp1.prototype.injectFooterJs = function() {
    
    // Create a new script element
    var footerJs = document.createElement('script');

    // Add sentinel footer script
    footerJs.textContent = `if(window.websiteSentinel) {

        window.websiteSentinel.sendFtPl();
        window.websiteSentinel.setMarkers();
    }

    //Check for GA on pageLoad after GA has tried to load
    window.onload = function() {

        if(window.websiteSentinel) {

            window.websiteSentinel.checkForGA();
        }
    };`;

    if (!this.extractKeyValue("footerTimestamp")) {
        
        // DOM is still loading, wait for it to be ready
        window.addEventListener('DOMContentLoaded', () => {
            
            this.appendToFooter(footerJs);
            //this.startSession();
        });
    } else {
        
        // DOMContentLoaded has already fired, append immediately
        this.appendToFooter(footerJs);
        //this.startSession();
    }
}

senp1.prototype.startSession = function() {

    var currentSession = this.checkSessionCookie();
    if (currentSession===null) {
        
        // If no session exists or session is expired, create a new session
        this.createNewSession();
    }

    // Listen for user activity to reset session expiration timer
    document.addEventListener("mousemove", () => {
        
        this.debouncedResetTimer();
    });
    document.addEventListener("keypress", () => {
        
        this.debouncedResetTimer();
    });
}

// Function to create a new session ID and set cookies
senp1.prototype.createNewSession = function() {
    
    var sessionId = this.generateSessionId();
    var active = new Date().toISOString();
    this.setCookie("sentinelbi_session", {session_id: sessionId, last_active: active});
    return sessionId;
}

// Function to generate a unique session ID (e.g., using timestamp and random value)
senp1.prototype.generateSessionId = function() {
    
    return 'sess-' + Math.random().toString(36).substr(2, 9) + '-' + new Date().getTime();
}

// Debounced function to reset session timer
senp1.prototype.debouncedResetTimer = function() {
    
    clearTimeout(this.debounceResetTimer);
    
    this.debounceResetTimer = setTimeout(() => { 
        this.resetSessionTimer();
    }, 500);
  }

// Function to reset session on user activity (mouse move, keypress)
senp1.prototype.resetSessionTimer = function() {
    
    var currentSession = this.checkSessionCookie();

    if (currentSession===null) {
        
        // If session is expired or doesn't exist, create a new one
        this.createNewSession();
    } else {
        
        // Parse the last_active timestamp
        const lastActive = new Date(currentSession.last_active).getTime();
        const currentTime = new Date().getTime();

        var sessionId = this.getCookieValueByKey("sentinelbi_session", "session_id");
        var active = new Date().toISOString();
        
        if((currentTime-lastActive) > this.sessionDuration){

            sessionId = this.generateSessionId();
        }

        this.setCookie("sentinelbi_session", {session_id: sessionId, last_active: active});
    }
}

// Function to check if session exists or is expired
senp1.prototype.checkSessionCookie = function() {
    
    var sessionCookie = this.getCookie("sentinelbi_session");

    if (sessionCookie!==null) {
        
        return JSON.parse(decodeURIComponent(sessionCookie));
    }
    
    return null;
}

// Helper function to set a cookie with name, value, and expiration time (in milliseconds)
senp1.prototype.setCookie = function(name, sessionObject) {
    
    // Validate that sessionObject is an object
    if (typeof sessionObject !== 'object' || sessionObject === null) {
        
        return;
    }

    // Convert the sessionObject into a JSON string
    const value = encodeURIComponent(JSON.stringify(sessionObject));

    // Set new cookie
    document.cookie = `${name}=${value}; path=/; SameSite=Strict; Secure`;  // expires=${expires.toUTCString()};

};

// Helper function to get cookie by name, decode its value, and return it
senp1.prototype.getCookie = function(name) {
    
    var cookies = document.cookie.split("; ");
    
    for (let cookie of cookies) {
        
        var [key, value] = cookie.split("=");
        if (key === name) return decodeURIComponent(value);
    }

    return null;
}

senp1.prototype.getCookieValueByKey = function(name, key) {
    
    var cookie = this.getCookie(name);
    if (!cookie) {
        
        return null;
    }

    var parsedObject = JSON.parse(decodeURIComponent(cookie));
    return parsedObject[key] !== undefined ? parsedObject[key] : null;
};

senp1.prototype.appendToFooter = function(script) {
    
    // Find the last footer element to append the script
    var footer = document.querySelectorAll('footer')[document.querySelectorAll('footer').length - 1]; 
    
    // Ensure this element exists
    if (footer) {
        
        footer.appendChild(script);
    } else {
        
        // if footer doesn't exist fall back and append to body
        document.body.appendChild(script);
    }
}

senp1.prototype.setMarkers = function() {

    this.createPageDepthMarkers();
    this.createEngagedPageTimers();
}

senp1.prototype.createPageDepthMarkers = function() {

    if(this.pageLevelDefinition.engagementContainer === 'body' || document.querySelector('#' +this.pageLevelDefinition.engagementContainer) === null){
        
        element = document.body;
    }
    else{

        element = document.querySelector('#' +this.pageLevelDefinition.engagementContainer);
    }

    var elementStartPos = element.getBoundingClientRect().top + document.documentElement.scrollTop;
    var elementHeight = element.offsetHeight;

    var snObj = this;

    var depthMarkerPercentages = [0, 20, 40, 60, 80, 100];
    depthMarkerPercentages.forEach(function(percentage) {

        setupPercentMarker(snObj, elementStartPos, elementHeight, percentage);
    });
}

function setupPercentMarker(snObj, elementStartPos, elementHeight, percentage) {

    var maxEngagedDepthTime = 30 * 60 * 1000;
    var offsetHeight = (elementHeight * percentage / 100) + elementStartPos;
    
    function handleScroll() {
        
        if ((window.scrollY + window.innerHeight) > offsetHeight) {
            
            var elapsedTime = Date.now() - snObj.extractKeyValue("footerTimestamp");
            if (elapsedTime < maxEngagedDepthTime) {
                
                snObj.percentMarkerHandler(percentage);
            }
            window.removeEventListener('scroll', handleScroll);
        }
    }
    
    window.addEventListener('scroll', handleScroll);
}

senp1.prototype.percentMarkerHandler = function(percentage) {
    
    var timestamp = Date.now();
    this.sendPl(timestamp,"engagedDepth", percentage);
}

senp1.prototype.createEngagedPageTimers = function() {
    
    const maxEngagedTime = 30 * 60 * 1000;
    var totalTimeEngaged = 0;
    var timerPos = 0;
    var timers = [5000,10000,15000,25000,35000,45000,65000,85000,105000,145000,185000,225000,305000,385000,465000,625000,785000,945000,1265000,1585000,1905000];
    var snObj = this;
    var timerId;
    var pausedTimer = false;
    
    function recursiveTimeout() {
        
        if(totalTimeEngaged > maxEngagedTime){
            return;
        }
        
        timerId = setTimeout(recursiveTimeout, timers[timerPos]-totalTimeEngaged);
        
        if(!pausedTimer){
            
            if(totalTimeEngaged>0){
                
                snObj.engagedTimeHandler(timers[timerPos-1] / 1000);
            }
            
            totalTimeEngaged = timers[timerPos];
            timerPos += 1;
        }
        
    }
    
    function pauseTimers() {
        
        clearTimeout(timerId);
        pausedTimer = true;
    }
    
    function resumeTimers() {
        
        recursiveTimeout();
        pausedTimer = false;
    }
    
    document.addEventListener("visibilitychange", function() {
        
        if (document.visibilityState === "hidden") {
            
            pauseTimers();
        } else {
            
            resumeTimers();
        }
    });
    
    recursiveTimeout();
}

senp1.prototype.engagedTimeHandler = function(seconds) {
    
    var timestamp = Date.now();
    this.sendPl(timestamp, "engagedSeconds", seconds);
}

senp1.prototype.extractKeyValue = function(key) {

    var sentinelData = (!window.sentinelData) ? window.sentinel : window.sentinelData;
    
    if (!sentinelData || !sentinelData.length) {
        return undefined; // Return undefined if arr is not valid or empty
    }

    const pair = sentinelData.find(([k, v]) => k === key);
    // If the pair is found, return the value (second element)
    return pair ? pair[1] : undefined; // Return undefined if key is not found
}

senp1.prototype.generateUUID = function() {
    
    var uuid;
    
    // Check if crypto.randomUUID() is supported
    if (typeof crypto.randomUUID === 'function') {
        
        // Use crypto.randomUUID() if supported
        uuid = crypto.randomUUID();
    } else {
        
        // Fallback method for generating UUIDs
        function fallbackUUID() {
            
            // Implementation of fallback UUID generation algorithm
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                
                var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
        
        // Use the fallback method to generate UUID
        uuid = fallbackUUID();
    }
    
    return uuid;
}

window.senp1 = senp1;
window.websiteSentinel = new senp1(pageLevelDefinitionObj)
window.websiteSentinel.injectFooterJs();